import Header from "../components/header"
import { motion } from "framer-motion" 
import React, { useState, useRef } from "react";

import avatar from "../assets/avatar.png"
import topback from "../assets/topbackground.png"
import topscreens from "../assets/topscreens.png"
import competepreview from "../assets/compete_preview.png"
import spotuppreview from "../assets/spotup_preview.png"
import appstore from "../assets/appstore_logo.png"

import { collection, addDoc } from "firebase/firestore";
import { firestore } from '../lib/firebase';

var topbackStyle = {
    backgroundImage: `url(${ topback })`
};

export default function Start() {

    const projectsRef = useRef(React.createRef());
    const contactRef = useRef(React.createRef());

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);

    async function send() {
        setSuccess(false)
        setFailed(false)
        if (firstName.length > 0 && lastName.length > 0 && email.length > 0 && message.length > 0) {
            addDoc(collection(firestore, "requests"), {
                name: firstName + " " + lastName,
                email: email,
                message: message
            }).then(function() {
                setFirstName("");
                setLastName("");
                setEmail("");
                setMessage("");
                setSuccess(true)
            })
            .catch(function() {
                console.log("error");
            });
        } else {
            setFailed(true)
        }
    }

    function scrollToProjects() {
        projectsRef.current.scrollIntoView();
    }

    function scrollToContact() {
        contactRef.current.scrollIntoView();
    }

    return (
        <div>
            <div className="start-top section" style={topbackStyle}>
                <div className="container start-top-container">
                    <div className="start-top-header">
                        <img src={avatar} />
                        <span>Hi, I'm</span> David
                    </div>
                    <div className="start-top-cells">
                        <div className="start-top-left">
                            <div className="top-heading">
                                <div className="top-heading-cell">
                                    iOS App
                                </div>
                                <div className="top-heading-cell top-heading-cell-bottom">
                                    Development
                                </div>
                            </div>
                            <div className="top-buttons">
                                <motion.div className="primary-btn"
                                    onClick={() => scrollToContact()}
                                    whileTap={{
                                        scale: 0.96
                                    }}
                                >
                                    Get in touch
                                </motion.div>
                                <motion.div className="secondary-btn"
                                    onClick={() => scrollToProjects()}
                                    whileTap={{
                                        scale: 0.96
                                    }}
                                >
                                    Projects
                                </motion.div>
                            </div>
                        </div>
                        <div className="start-top-right">
                            <img src={topscreens} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="start-projects section" ref={projectsRef}>
                <div className="container start-projects-container">
                    <div className="start-projects-header">
                        <div className="separator"></div>
                        <h2>Projects</h2>
                    </div>
                    <div className="projects-grid">
                        <div className="project-cell">
                            <img src={competepreview} />
                            <div className="project-cell-bottom">
                                <div className="project-cell-left">
                                    <div className="project-title">
                                        Compete - Minigame Duels
                                    </div>
                                    <div className="project-description">
                                        Play skill-based mini games against your friends and others
                                    </div>
                                </div>
                                <a target='_blank' rel='noopener noreferrer' href="https://apps.apple.com/de/app/compete-minigame-duels/id6476064566">
                                    <img src={appstore} />
                                </a>
                            </div>
                        </div>
                        <div className="project-cell">
                            <img src={spotuppreview} />
                            <div className="project-cell-bottom">
                                <div className="project-cell-left">
                                    <div className="project-title">
                                        SpotUp - Share the Where
                                    </div>
                                    <div className="project-description">
                                        Location-based social media
                                    </div>
                                </div>
                                <a target='_blank' rel='noopener noreferrer' href="https://apps.apple.com/de/app/spotup-share-the-where/id6474980496">
                                    <img src={appstore} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="start-contact section" ref={contactRef}>
                <div className="container start-contact-container">
                    <div className="start-projects-header">
                        <div className="separator"></div>
                        <h2>Contact</h2>
                    </div>
                    <div className="contact-form">
                        <div className="form-line">
                            <div className="textfield">
                                <div>First Name</div>
                                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            </div>
                            <div className="textfield">
                                <div>Last Name</div>
                                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-line">
                            <div className="textfield">
                                <div>E-Mail</div>
                                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-line">
                            <div className="textfield">
                                <div>Message</div>
                                <textarea name="text" value={message} onChange={(e) => setMessage(e.target.value)} />
                            </div>
                        </div>
                        {success ? <div className="success-message">The request was sent successfully.</div> :
                            <div>
                                {failed ? <div className="error-message">All fields have to be filled out.</div> :
                            <div></div>
                        }
                            </div>
                        }
                        <motion.div className="primary-btn-fill"
                            onClick={() => send()}
                            whileTap={{
                                scale: 0.96
                            }}
                        >
                            Send Request
                        </motion.div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="container footer-container">

                </div>
            </div>
        </div>
    )
}