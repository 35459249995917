import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const config = {
    apiKey: "AIzaSyCotWwJNryAqwBqNc4o1fxgWatM7FifTyI",
    authDomain: "astro-d73e2.firebaseapp.com",
    projectId: "astro-d73e2",
    storageBucket: "astro-d73e2.appspot.com",
    messagingSenderId: "545832321314",
    appId: "1:545832321314:web:01c3549d790249002b657b",
    measurementId: "G-B8869XFESS"
  };

  const firebase = initializeApp(config);
  const firestore = getFirestore(firebase)

  export { firebase, firestore };